import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";

import { CircularProgress, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { Reservation } from "components/Reservation/Reservation.jsx";
import { TerritoryContext } from "contexts/TerritoryContext";

import { fetchReservation } from "./actions.js";

function ReservationContainer(props) {
  const {
    error,
    fetchReservation,
    id,
    isRequesting,
    recurringReservations,
    reservation,
  } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  const cancellationTreshold =
    selectedTerritory?.booking?.cancellation_threshold || 0;
  const canChangeSeats =
    selectedTerritory?.booking?.change_booking_seats?.enabled &&
    !selectedTerritory?.payment.enabled;

  // maybe should clear the last one if change from one to another
  useEffect(() => {
    fetchReservation(id, selectedTerritory.territory_key);
  }, [id, selectedTerritory.territory_key]);

  if (isRequesting)
    return (
      <div className="row-only centered aligned container">
        <CircularProgress />
      </div>
    );

  if (error || !reservation) {
    if (error) {
      console.log(`Error while fetching reservation: ${error}`);
    } else {
      console.log("Error while fetching reservation: no reservation found");
    }

    return (
      <div className="row-only centered aligned container">
        <Typography color="error">
          {
            "An unknown error has occured, please try again later or contact your operator"
          }
        </Typography>
      </div>
    );
  }

  // IS RECURRING
  // we pass info because we don't get it via get-current-reservations-info
  // and we need it for now in order to hide the EDIT button
  let isRecurring = false;

  if (_.find(recurringReservations, (r) => r.id === reservation.id)) {
    isRecurring = true;
  }

  // IS NOT PAST
  const now = window.Cypress ? dayjs(1582949766) : dayjs(new Date());
  const pickupTime = dayjs(reservation.pickup_time);

  const minutesDiff = pickupTime.diff(now, "minutes");

  const isNotPast = Boolean(
    minutesDiff > 0 && reservation.status === "VALIDATED",
  );

  // IS DELETABLE
  const isDeletable = Boolean(
    minutesDiff > cancellationTreshold && reservation.status === "VALIDATED",
  );

  return (
    <Reservation
      canChangeSeats={canChangeSeats}
      isDeletable={isDeletable}
      isNotPast={isNotPast}
      isRecurring={isRecurring}
      reservation={reservation}
    />
  );
}

const _mapState = (state) => {
  return {
    reservation: state.reservation.reservation,
    recurringReservations: state.reservationList.recurring,
    isRequesting: state.reservation.isRequesting,
    error: state.reservation.error,
  };
};

const _mapDispatch = (dispatch) => ({
  fetchReservation: (id, selectedTerritoryKey) => {
    dispatch(fetchReservation(id, selectedTerritoryKey));
  },
});

export default connect(_mapState, _mapDispatch)(ReservationContainer);
