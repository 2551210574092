import * as React from "react";
import { createContext, useEffect, useState, useMemo } from "react";

import * as Sentry from "@sentry/react";

import api from "apiAxios";

import { GetParametersOutput } from "api/types/getParameters";
import { LocalTerritory, RegionalTerritory } from "api/types/common";

type ProductParameters = RegionalTerritory;
type Territory = LocalTerritory;
interface ProductContextValue {
  productParameters: ProductParameters | undefined;
  commonTerritories: Territory[];
  productLoading: boolean;
  setProductParameters: (parameters: ProductParameters) => void;
  setCommonTerritories: (territories: Territory[]) => void;
}

export const ProductContext = createContext<ProductContextValue>({
  productParameters: undefined,
  commonTerritories: [],
  productLoading: false,
  setProductParameters: () => null,
  setCommonTerritories: () => null,
});

export const ProductContextProvider = ({ children }) => {
  const [productParameters, setProductParameters] = useState<
    ProductParameters | undefined
  >(undefined);
  const [commonTerritories, setCommonTerritories] = useState<Territory[]>([]);

  // parametersLoading is set to true by default since we want
  // the compoennt to return null at first render.
  const [productLoading, setProductLoading] = useState(true);

  const value = useMemo(
    () => ({
      productParameters,
      commonTerritories,
      productLoading,
      setProductParameters,
      setCommonTerritories,
    }),
    [
      productParameters,
      commonTerritories,
      productLoading,
      setProductParameters,
      setCommonTerritories,
    ],
  );

  useEffect(() => {
    setProductLoading(true);
    api
      .getParameters({})
      .then((parameters: GetParametersOutput) => {
        setProductParameters(parameters.regional);
        setCommonTerritories(parameters.locals);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setProductLoading(false);
      });
  }, []);

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};
