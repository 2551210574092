import React, { useContext, useEffect } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { FormattedMessage } from "react-intl-phraseapp";

import * as Sentry from "@sentry/react";

import { UserContext } from "contexts/UserContext";
import { ProductContext } from "contexts/ProductContext";
import { settings } from "config/app";

import api from "apiAxios";

import styles from "./languageSelector.cssmodule.scss";
import { GetParametersOutput } from "api/types/getParameters";

const languageItems = [
  { value: "en", id: "english_language" },
  { value: "fr", id: "french_language" },
  { value: "de", id: "german_language" },
  { value: "es", id: "spanish_language" },
  { value: "ca", id: "catalan_language" },
  { value: "fr-ca", id: "french_canadian_language" },
  { value: "it", id: "italian_language" },
  { value: "nl", id: "dutch_language" },
  { value: "sv", id: "swedish_language" },
  { value: "da", id: "danish_language" },
];

export default function LanguageSelector({
  textColor = "black",
  isOpen,
}: {
  textColor: string;
  isOpen?: boolean;
}) {
  const { language, setLanguage } = useContext(UserContext);
  const { setProductParameters, setCommonTerritories } =
    useContext(ProductContext);

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    window.localStorage.setItem(settings.localStorageKeys.language, lang);
    getNewParams();
  };

  const getNewParams = () => {
    api
      .getParameters({})
      .then((parameters: GetParametersOutput) => {
        setProductParameters(parameters.regional);
        setCommonTerritories(parameters.locals);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <Select
      disableUnderline
      value={language}
      onChange={handleLanguageChange}
      className={styles.languageSelector}
      style={{
        color: textColor,
        stroke: textColor,
      }}
      SelectDisplayProps={{
        style: {
          display: "flex",
          alignItems: "center",
          height: "100%",
          paddingLeft: "0.5rem",
        },
      }}
      open={isOpen}
    >
      {languageItems.map((lang) => (
        <MenuItem key={lang.value} value={lang.value}>
          <FormattedMessage id={lang.id} />
        </MenuItem>
      ))}
    </Select>
  );
}
